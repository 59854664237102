import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import moment from 'moment';
// utils
import axios from '../../utils/axios';
import i18n from '../../locales/i18n';
import { customPointDateFormat } from '../../utils/formatTime';
//
import { dispatch } from '../store';

const initialState = {
    isLoading: false,
    exeServices: [],
    dicStationsAll: [],
    // period: null
    
    period: {
      dateBeg: new Date().setMonth(new Date().getMonth() - 3),
      dateEnd: new Date().setMonth(new Date().getMonth() + 6), 
    }
    
    
};

const slice = createSlice({
    name: 'exeServices',
    initialState,
    reducers: {
        // START LOADING
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setExeServices(state, action) {
            state.exeServices = action.payload;
        },
        setDicStationsAll(state, action) {
            state.dicStationsAll = action.payload;
        },
        /*
        setDateBeg(state, action) {
          state.dateBeg = action.payload;
        },
        setDateEnd(state, action) {
          state.dateEnd = action.payload;
        },
        */
        setPeriod(state, action) {
          state.period = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setLoading,
    setExeServices,
    setDicStationsAll,
    // setDateBeg,
    // setDateEnd
    setPeriod
} = slice.actions;
/*
// --------НДІ Станції
export const getDicStationsAll = async () => {
    await axios.get(`/api-nsi/api/v1/DataNsi/ThreeColumns?tableName=views.V_Stations&fieldOutOne=display_info&fieldOutTwo=code_station&fieldOutThree=code_adm&all=true`)  
      .then(res => {
        if(res.status === 200) {
          const newData = [];        
          res.data.forEach(item => {            
            const newItem = {'value': item.field2, 'title': item.field1, 'code': item.field3};
            if (!newData.includes(newItem)) {
              newData.push(newItem)
            } 
            else {      
              throw new Error(res.statusText || res.status);          
            }                  
          });  
          dispatch(setDicStationsAll(newData));
        }
      })
      .catch((error) => {    
        toast.warn(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
      });        
  };
*/
// --------Перелік послуг для груп вагорів у контракті
// 
  export const getExeServicesByPeriod = async (idVsp, 
                                              currentDateBeg=moment(new Date().setMonth(new Date().getMonth() - 3)).format('DD.MM.yyyy'), 
                                              currentDateEnd=moment(new Date().setMonth(new Date().getMonth() + 6)).format('DD.MM.yyyy')) => {
    console.log('currentDateBeg', currentDateBeg);
    console.log('currentDateEnd', currentDateEnd);
    dispatch(setLoading(true));
    
    const data =`"SELECT ` +
                `    services.id_service as idService                                               ` +
                `    , services.code_service as  codeService                                        ` +
                `    , services.is_key  as isKey                                                    ` +
                
                `    , services.name_service as nameService                                         ` +
                `    /*, main.id_slc, main.name_slc, main.id_route*/                                ` +
                `    , services.date_plan_beg   as dateBegin                                        ` +
                `    , services.date_plan_end   as dateEnd                                          ` +
                `    , services.service_duration as duration                                        ` +
                `    /* , services.id_group  если null, то выполняется для всех групп маршрута */   ` +
                `    /* , main.id_route */                                                          ` +
                `   , IF(services.id_group IS NULL, 'route', 'carGroup') as nameObject              ` +
                `    , IF(services.id_group IS NULL, main.id_route, services.id_group) as idObject  ` +
                `    , IF(services.id_group IS NULL, routes.count_vag, gr.count_vag) as countObjects  ` +
                `    , services.cost_actual as cost                                                 ` +
                `    , dic_currencys.k_valc as currencyName                                         ` +

                `    , executors.id_agreement as idAgreement                                        ` +
                `    , agreements.number_agreement as numberAgreement                               ` +
                `    , agreements.agree_date  as dateAgreement                                      ` +
                `    /*, agreements.id_pidpr_saller*/                                               ` +
                `    /*, dic_pidpr_saller.name_short as saller*/                                    ` +
                `    , agreements.id_customer as idCustomer                                         ` +
                `    , dic_pidpr_customer.name_short as nameCustomer                                ` +

                `  FROM IC.Slc_Services services ` +
                `  INNER JOIN IC.Slc_Main main on services.id_slc = main.id_slc and main.status = 3 and main.type_slc = 1 and main.id_route is not null  ` +
                `  INNER JOIN IC.Cargo_Route routes on main.id_route = routes.id_route  ` +
                `  INNER JOIN IC.Slc_Services_Executors executors on services.id_service = executors.id_service   ` +
                `  INNER JOIN pidpr.Ekbs_Pidpr_Agreements agreements on executors.id_agreement = agreements.id_agree and agreements.id_saller=${idVsp} ` +
                `  /*INNER JOIN views.V_Ekbs_Pidpr_All dic_pidpr_saller on agreements.id_saller = dic_pidpr_saller.id_vsp*/ ` +
                `  INNER JOIN views.V_Ekbs_Pidpr_All dic_pidpr_customer ON agreements.id_customer = dic_pidpr_customer.id_vsp ` +
                `  /*LEFT JOIN nsi_ekbs.Dic_Pidpr_Services dic_services on dic_services.code_service = services.code_service*/  ` +
                `  LEFT JOIN nsi.E_D_VALUT dic_currencys ON dic_currencys.k_valn = services.currency_code ` +
                `  LEFT JOIN IC.Cargo_Group gr on services.id_group = gr.id_group ` +

                `  WHERE services.date_plan_beg BETWEEN STR_TO_DATE('${currentDateBeg}', '%d.%c.%Y') AND STR_TO_DATE('${currentDateEnd}', '%d.%c.%Y') ` +

                `  order by services.date_plan_beg  "` ;
                
    return axios.post(`/api-vantazh/NsiData`, data, { headers: {  'Content-Type': 'text/json' } } ) 
    .then(res => {
        if(res.status === 200) {  
            console.log("getExeServicesByPeriod", res.data);    
            dispatch(setExeServices(res.data));
        }          
    })
    .catch((error) => {      
        // console.log(error);
      toast.error(`${i18n.t('service.errors.error')}: ${error}`, {autoClose: false, hideProgressBar: false, closeOnClick: true}); 
    })
    .finally(() => dispatch(setLoading(false)) );
  };
/*
// --------Оновлення періода запиту даних
// 
export const persistPeriod = async (newPeriod) => {
  dispatch(setPeriod(newPeriod));             
}
*/
// --------Оновлення періода запиту даних
// 
  export const persistPeriod = async (newPeriod) => {
    console.log('newPeriod', newPeriod);
    dispatch(setPeriod(newPeriod));             
  }